<template>
  <div>
      <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDAC53"
      spinner="bar-fade-scale"
    />
     <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout wrap>
       <v-flex xs12 text-left>
        <span style="color: #000; font-size: 20px; font-family: poppinsbold"
          >Contact List</span
        >
      </v-flex>
      <v-flex xs12 pt-4 v-if="contact.length>0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left text-uppercase">Name</th>
                <th class="text-left text-uppercase">Email</th>
                <th class="text-left text-uppercase">Phone</th>
                <th class="text-left text-uppercase">Interested</th>
                <th class="text-center text-uppercase">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr style="height: 90px" v-for="(item, i) in contact" :key="i">
                <td class="text-left">{{ item.name }}</td>
                <td class="text-left">{{ item.email }}</td>
                <td class="text-left">{{ item.phone}}</td>
                <td v-if="item.interestedIn" class="text-left">{{ item.interestedIn.name}}</td>
                <td class="text-left">
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm3 lg4 align-self-center>
                      <v-btn plain text :to="'/contactDetails?contact=' + item._id">
                        <v-icon color="black">mdi-eye</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <v-flex xs12 v-else>
        <span class="textField2"> No Updates Found!! ! </span>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap justify-center v-if="contact.length > 0">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#FDAC53"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      currentPage: 1,
      pages: 0,
      count: 15,
      appLoading: false,
      ServerError: false,
      snackbar: false,
      timeout: 5000,
      msg: null,
      contact: [],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/contact/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
         data: {
            count: this.count,
            page: this.currentPage,
          },
      })
        .then((response) => {
          if (response.data.status) {
            this.contact = response.data.data;
            this.pages = response.data.pages;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>